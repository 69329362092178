window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.front_royal_form = window.locales.am.front_royal_form || {};window.locales.am.front_royal_form.inputs = window.locales.am.front_royal_form.inputs || {};window.locales.am.front_royal_form.inputs.write_text_about = window.locales.am.front_royal_form.inputs.write_text_about || {};window.locales.am.front_royal_form.inputs.write_text_about = {...window.locales.am.front_royal_form.inputs.write_text_about, ...{
    "num_remaining": "ከፍተኛ {{maxNum}} ({{num}} የቀረው)",           
    "please_fill_in_english": "እባኮትን በእንግሊዘኛ መልሱ",
    "please_complete": "ምላሾች በ {{minNum}} እና {{maxNum}} ቁምፊዎች መካከል መሆን አለባቸው። ( {{chars}} )"
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.front_royal_form = window.locales.ar.front_royal_form || {};window.locales.ar.front_royal_form.inputs = window.locales.ar.front_royal_form.inputs || {};window.locales.ar.front_royal_form.inputs.write_text_about = window.locales.ar.front_royal_form.inputs.write_text_about || {};window.locales.ar.front_royal_form.inputs.write_text_about = {...window.locales.ar.front_royal_form.inputs.write_text_about, ...{
    "num_remaining": "الحد الأقصى {{maxNum}} ( {{num}} متبقي)",
    "please_fill_in_english": "الرجاء الإجابة باللغة الإنجليزية",
    "please_complete": "يجب أن تكون الإجابات بين {{minNum}} و {{maxNum}} حرفًا. ( {{chars}} )"
}
};window.locales.en = window.locales.en || {};window.locales.en.front_royal_form = window.locales.en.front_royal_form || {};window.locales.en.front_royal_form.inputs = window.locales.en.front_royal_form.inputs || {};window.locales.en.front_royal_form.inputs.write_text_about = window.locales.en.front_royal_form.inputs.write_text_about || {};window.locales.en.front_royal_form.inputs.write_text_about = {...window.locales.en.front_royal_form.inputs.write_text_about, ...{
    "num_remaining": "Max {{maxNum}} ({{num}} remaining)",
    "please_fill_in_english": "Please answer in English",
    "please_complete": "Answers must be between {{minNum}} and {{maxNum}} characters. ({{chars}})"
}
};window.locales.es = window.locales.es || {};window.locales.es.front_royal_form = window.locales.es.front_royal_form || {};window.locales.es.front_royal_form.inputs = window.locales.es.front_royal_form.inputs || {};window.locales.es.front_royal_form.inputs.write_text_about = window.locales.es.front_royal_form.inputs.write_text_about || {};window.locales.es.front_royal_form.inputs.write_text_about = {...window.locales.es.front_royal_form.inputs.write_text_about, ...{
    "num_remaining": "Max {{maxNum}} ( {{num}} restante)",
    "please_fill_in_english": "Por favor responda en ingles",
    "please_complete": "Las respuestas deben tener entre {{minNum}} y {{maxNum}} caracteres. ( {{chars}} )"
}
};window.locales.it = window.locales.it || {};window.locales.it.front_royal_form = window.locales.it.front_royal_form || {};window.locales.it.front_royal_form.inputs = window.locales.it.front_royal_form.inputs || {};window.locales.it.front_royal_form.inputs.write_text_about = window.locales.it.front_royal_form.inputs.write_text_about || {};window.locales.it.front_royal_form.inputs.write_text_about = {...window.locales.it.front_royal_form.inputs.write_text_about, ...{
    "num_remaining": "Max {{maxNum}} ( {{num}} rimanenti)",
    "please_fill_in_english": "Si prega di rispondere in inglese",
    "please_complete": "Le risposte devono essere comprese tra {{minNum}} e {{maxNum}} caratteri. ( {{chars}} )"
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.front_royal_form = window.locales.zh.front_royal_form || {};window.locales.zh.front_royal_form.inputs = window.locales.zh.front_royal_form.inputs || {};window.locales.zh.front_royal_form.inputs.write_text_about = window.locales.zh.front_royal_form.inputs.write_text_about || {};window.locales.zh.front_royal_form.inputs.write_text_about = {...window.locales.zh.front_royal_form.inputs.write_text_about, ...{
    "num_remaining": "最大{{maxNum}} （{{num}} 剩余）",           
    "please_fill_in_english": "请以英文回答",           
    "please_complete": "答案必须介于{{minNum}}和{{maxNum}}字符之间。 （ {{chars}} ）"
}
};