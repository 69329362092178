window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.front_royal_api_error_handler = window.locales.am.front_royal_api_error_handler || {};window.locales.am.front_royal_api_error_handler.api_error_handler = window.locales.am.front_royal_api_error_handler.api_error_handler || {};window.locales.am.front_royal_api_error_handler.api_error_handler = {...window.locales.am.front_royal_api_error_handler.api_error_handler, ...{
    "unable_to_contact_app_servers": "ሰርቨሮቻችንን ማግኘት አልቻልንም።",           
    "something_went_wrong": "የሆነ ስህተት ተከስቷል።  የእርስዎ ጥፋቱ አይደለም።\n\nመሀንዲሶቻችን ስለ ጉዳዩ ማሳወቂያ ተደርገዋል።\n\nወደ ዳሽቦርዱ ለመመለስ ይንኩ።",           
    "you_do_not_have_content_permissions": "ይህን ይዘት ለማየት ፈቃድ የለዎትም።\n\nእባክዎ ወደ ዳሽቦርዱ ለመመለስ ጠቅ ያድርጉ።",           
    "servers_are_down": "የእኛ ሰርቨሮች በአሁኑ ጊዜ ዝግ ናቸው።  መሀንዲሶች ችግሩን ለማስተካከል እየሰሩ ነው።\n\n ለተፈጠረው ችግር ይቅርታ እንጠይቃለን።",           
    "you_do_not_have_permission": "ያንን ለማድረግ ፈቃድ የለዎትም። እባክዎ ለመቀጠል ጠቅ ያድርጉ።",           
    "login_required": "ማስገባት ያስፈልጋል",           
    "server_error": "የሰርቨር ስህተት",           
    "could_not_find": "የሚፈልጉትን ማግኘት አልቻልንም።",           
    "something_is_missing": "የሆነ ነገር ይጎድላል",           
    "could_not_find_return_home": "የሚፈልጉትን ማግኘት አልቻልንም።<br/><br/> እባክዎ ወደ ዳሽቦርዱ ለመመለስ ጠቅ ያድርጉ።",
    "something_went_wrong_check_back_later": "የሆነ ስህተት ተከስቷል. ያንተ ጥፋት አይደለም።<br /> ስለ ጉዳዩ መሐንዲሶቻችን ተነግሮላቸዋል።<br /> እባክህ ቆይተህ ተመልከት።"
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.front_royal_api_error_handler = window.locales.ar.front_royal_api_error_handler || {};window.locales.ar.front_royal_api_error_handler.api_error_handler = window.locales.ar.front_royal_api_error_handler.api_error_handler || {};window.locales.ar.front_royal_api_error_handler.api_error_handler = {...window.locales.ar.front_royal_api_error_handler.api_error_handler, ...{
    "you_do_not_have_content_permissions": "ليس لديك إذن لاستعراض هذا المحتوى.\r\n\r\nيرجى النقر للعودة إلى لوحة التحكم.",           
    "you_do_not_have_permission": "ليس لديك الأذن للقيام بذلك. يرجى النقر للاستمرار.",           
    "login_required": "تسجيل الدخول مطلوب",           
    "server_error": "خطأ في الخادم",           
    "unable_to_contact_app_servers": "لم نتمكن من الاتصال بخوادمنا.",
    "something_went_wrong": "هناك خطأ ما. انها ليست غلطتك. لقد تم إخطار مهندسينا بهذه القضية. انقر للعودة إلى لوحة القيادة.",
    "servers_are_down": "خوادمنا معطلة حاليًا. يعمل المهندسون لإصلاح المشكلة. نعتذر عن الإزعاج.",
    "could_not_find": "لم نتمكن من العثور على ما كنت تبحث عنه.",
    "something_is_missing": "شئ مفقود",
    "could_not_find_return_home": "لم نتمكن من العثور على ما كنت تبحث عنه.<br/><br/> الرجاء الضغط للعودة إلى لوحة القيادة.",
    "something_went_wrong_check_back_later": "هناك خطأ ما. انها ليست غلطتك.<br /> تم إخطار مهندسينا بالمشكلة.<br /> يرجى التحقق مرة أخرى في وقت لاحق."
}
};window.locales.en = window.locales.en || {};window.locales.en.front_royal_api_error_handler = window.locales.en.front_royal_api_error_handler || {};window.locales.en.front_royal_api_error_handler.api_error_handler = window.locales.en.front_royal_api_error_handler.api_error_handler || {};window.locales.en.front_royal_api_error_handler.api_error_handler = {...window.locales.en.front_royal_api_error_handler.api_error_handler, ...{
    "unable_to_contact_app_servers": "We were unable to contact our servers.",
    "something_went_wrong": "Something went wrong.  It's not your fault.\n\nOur engineers have been notified of the issue.\n\nClick to return to the dashboard.",
    "you_do_not_have_content_permissions": "You do not have permission to view this content.\n\nPlease click to return to the dashboard.",
    "servers_are_down": "Our servers are currently down.  Engineers are working to fix the issue.\n\nWe apologize for the inconvenience.",
    "you_do_not_have_permission": "You do not have permission to do that. Please click to continue.",
    "login_required": "Login Required",
    "server_error": "Server Error",
    "could_not_find": "We could not find what you were looking for.",
    "could_not_find_return_home": "We could not find what you were looking for.<br/><br/>Please click to return to the dashboard.",
    "something_is_missing": "Something is Missing",
    "something_went_wrong_check_back_later": "Something went wrong. It's not your fault.<br />Our engineers have been notified of the issue.<br />Please check back later."
}
};window.locales.es = window.locales.es || {};window.locales.es.front_royal_api_error_handler = window.locales.es.front_royal_api_error_handler || {};window.locales.es.front_royal_api_error_handler.api_error_handler = window.locales.es.front_royal_api_error_handler.api_error_handler || {};window.locales.es.front_royal_api_error_handler.api_error_handler = {...window.locales.es.front_royal_api_error_handler.api_error_handler, ...{
    "you_do_not_have_content_permissions": "No tienes permiso para ver este contenido.\n\nHaz clic para volver al panel de control.",           
    "you_do_not_have_permission": "No tienes permiso para hacer eso. Por favor, haz clic para continuar.",           
    "login_required": "Es necesario iniciar sesión",           
    "server_error": "Error del servidor",           
    "unable_to_contact_app_servers": "No pudimos contactar con nuestros servidores.",
    "something_went_wrong": "Algo salió mal. No es tu culpa. Nuestros ingenieros han sido notificados del problema. Haga clic para volver al tablero.",
    "servers_are_down": "Nuestros servidores están actualmente fuera de servicio. Los ingenieros están trabajando para solucionar el problema. Disculpe las molestias.",
    "could_not_find": "No pudimos encontrar lo que buscaba.",
    "something_is_missing": "Algo falta",
    "could_not_find_return_home": "No pudimos encontrar lo que estabas buscando.<br/><br/> Haga clic para volver al panel de control.",
    "something_went_wrong_check_back_later": "Algo salió mal. No es tu culpa.<br /> Nuestros ingenieros han sido notificados del problema.<br /> Por favor, vuelva más tarde."
}
};window.locales.it = window.locales.it || {};window.locales.it.front_royal_api_error_handler = window.locales.it.front_royal_api_error_handler || {};window.locales.it.front_royal_api_error_handler.api_error_handler = window.locales.it.front_royal_api_error_handler.api_error_handler || {};window.locales.it.front_royal_api_error_handler.api_error_handler = {...window.locales.it.front_royal_api_error_handler.api_error_handler, ...{
    "you_do_not_have_content_permissions": "Non hai l'autorizzazione a visualizzare questo contenuto.\n\nPer favore, fai clic per tornare alla bacheca.",           
    "you_do_not_have_permission": "Non hai l'autorizzazione per farlo. Per favore, fai clic per continuare.",           
    "login_required": "Accesso necessario",           
    "server_error": "Errore server",           
    "unable_to_contact_app_servers": "Non siamo riusciti a contattare i nostri server.",
    "something_went_wrong": "Qualcosa è andato storto. Non è colpa vostra. I nostri ingegneri sono stati informati del problema. Fare clic per tornare alla dashboard.",
    "servers_are_down": "I nostri server sono attualmente inattivi. Gli ingegneri stanno lavorando per risolvere il problema. Ci scusiamo per il disagio.",
    "could_not_find": "Non siamo riusciti a trovare quello che stavi cercando.",
    "something_is_missing": "Manca qualcosa",
    "could_not_find_return_home": "Non siamo riusciti a trovare quello che stavi cercando.<br/><br/> Fare clic per tornare alla dashboard.",
    "something_went_wrong_check_back_later": "Qualcosa è andato storto. Non è colpa tua.<br /> I nostri tecnici sono stati informati del problema.<br /> Per favore controllare più tardi."
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.front_royal_api_error_handler = window.locales.zh.front_royal_api_error_handler || {};window.locales.zh.front_royal_api_error_handler.api_error_handler = window.locales.zh.front_royal_api_error_handler.api_error_handler || {};window.locales.zh.front_royal_api_error_handler.api_error_handler = {...window.locales.zh.front_royal_api_error_handler.api_error_handler, ...{
    "you_do_not_have_content_permissions": "您没有查看此内容的权限。\n\n请单击返回控制面板。",           
    "you_do_not_have_permission": "您没有执行该操作的权限。请单击以继续。",           
    "login_required": "需要登录",           
    "server_error": "服务器错误",           
    "unable_to_contact_app_servers": "我们无法联系到服务器。",           
    "something_went_wrong": "情况不妙。  与你无关。\r\n\r\n我们的工程师已知晓该问题。\r\n\r\n点击返回仪表板。",           
    "servers_are_down": "我们的服务器目前已崩溃。  工程师们正在努力解决这个问题。\r\n\r\n给你带来不便，我们深表歉意。",           
    "could_not_find": "我们找不到你要找的东西。",           
    "something_is_missing": "内容缺失",           
    "could_not_find_return_home": "我们找不到您要找的东西。<br/><br/>请单击以返回仪表板。",
    "something_went_wrong_check_back_later": "出了些问题。这不是你的错。<br />我们的工程师已收到有关该问题的通知。<br />请稍后再回来查看。"
}
};