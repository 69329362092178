window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.front_royal_form = window.locales.am.front_royal_form || {};window.locales.am.front_royal_form.inputs = window.locales.am.front_royal_form.inputs || {};window.locales.am.front_royal_form.inputs.upload_avatar = window.locales.am.front_royal_form.inputs.upload_avatar || {};window.locales.am.front_royal_form.inputs.upload_avatar = {...window.locales.am.front_royal_form.inputs.upload_avatar, ...{
    "saved_automatically": "ፎቶ ከተሰቀለ በኋላ በራስሰር ይቀመጣል",           
    "upload": "ስቀል",           
    "x_mb_limit": "({{size}} ከፍተኛ መጠን)",           
    "sync_from_linked_in": "ከሊንከዲን ጋር አጣምር",           
    "camera_source_camera": "ፎቶ አንሳ",           
    "camera_source_photos": "ፎቶ ይምረጡ",           
    "picture_access_tip": "እባክዎ ይህንን ተይግቡር ለመጠቀም የካሜራ ወይም ቤተ-መፅሃፍት መዳረሻን ያንቁ።",           
    "linkedin_error": "ከሊንከዲን ጋር በማጣመር ላይ ስህተት ተፈጥሯል",           
    "linkedin_no_picture": "ሊንከዲን የግለ-መገለጫ ስዕልን ማግኘት አልቻለም"           
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.front_royal_form = window.locales.ar.front_royal_form || {};window.locales.ar.front_royal_form.inputs = window.locales.ar.front_royal_form.inputs || {};window.locales.ar.front_royal_form.inputs.upload_avatar = window.locales.ar.front_royal_form.inputs.upload_avatar || {};window.locales.ar.front_royal_form.inputs.upload_avatar = {...window.locales.ar.front_royal_form.inputs.upload_avatar, ...{
    "saved_automatically": "يتم حفظ الصورة تلقائياً بعد التحميل",           
    "upload": "تحميل",           
    "x_mb_limit": "(أقصى حجم {{size}})",           
    "sync_from_linked_in": "مزامنة من لينكدإن",
    "camera_source_camera": "تصوير",
    "camera_source_photos": "اختر الصورة",
    "picture_access_tip": "يرجى تمكين الوصول إلى الكاميرا أو المكتبة لاستخدام هذه الميزة.",
    "linkedin_error": "خطأ في المزامنة من LinkedIn",
    "linkedin_no_picture": "لم يتمكن LinkedIn من العثور على صورة ملف التعريف"
}
};window.locales.en = window.locales.en || {};window.locales.en.front_royal_form = window.locales.en.front_royal_form || {};window.locales.en.front_royal_form.inputs = window.locales.en.front_royal_form.inputs || {};window.locales.en.front_royal_form.inputs.upload_avatar = window.locales.en.front_royal_form.inputs.upload_avatar || {};window.locales.en.front_royal_form.inputs.upload_avatar = {...window.locales.en.front_royal_form.inputs.upload_avatar, ...{
    "saved_automatically": "Photo is saved automatically after upload",
    "upload": "Upload",
    "x_mb_limit": "({{size}} max size)",
    "sync_from_linked_in": "Sync From LinkedIn",
    "camera_source_camera": "Take Photo",
    "camera_source_photos": "Choose Photo",
    "picture_access_tip": "Please enable camera or library access to use this feature.",
    "linkedin_error": "Error syncing from LinkedIn",
    "linkedin_no_picture": "LinkedIn was unable to find a profile picture"
}
};window.locales.es = window.locales.es || {};window.locales.es.front_royal_form = window.locales.es.front_royal_form || {};window.locales.es.front_royal_form.inputs = window.locales.es.front_royal_form.inputs || {};window.locales.es.front_royal_form.inputs.upload_avatar = window.locales.es.front_royal_form.inputs.upload_avatar || {};window.locales.es.front_royal_form.inputs.upload_avatar = {...window.locales.es.front_royal_form.inputs.upload_avatar, ...{
    "saved_automatically": "La foto se guarda automáticamente después de ser subida",           
    "upload": "Subir",           
    "x_mb_limit": "({{size}} tamaño máximo)",           
    "sync_from_linked_in": "De sincronización LinkedIn",
    "camera_source_camera": "Tomar foto",
    "camera_source_photos": "Escoge una foto",
    "picture_access_tip": "Habilite el acceso a la cámara o a la biblioteca para usar esta función.",
    "linkedin_error": "Error al sincronizar desde LinkedIn",
    "linkedin_no_picture": "LinkedIn no pudo encontrar una foto de perfil"
}
};window.locales.it = window.locales.it || {};window.locales.it.front_royal_form = window.locales.it.front_royal_form || {};window.locales.it.front_royal_form.inputs = window.locales.it.front_royal_form.inputs || {};window.locales.it.front_royal_form.inputs.upload_avatar = window.locales.it.front_royal_form.inputs.upload_avatar || {};window.locales.it.front_royal_form.inputs.upload_avatar = {...window.locales.it.front_royal_form.inputs.upload_avatar, ...{
    "saved_automatically": "La foto viene salvata automaticamente dopo il caricamento",           
    "upload": "Carica",           
    "x_mb_limit": "(dimensioni massime {{size}})",           
    "sync_from_linked_in": "Sincronizza da LinkedIn",           
    "camera_source_camera": "Fare foto",
    "camera_source_photos": "Scegli la foto",
    "picture_access_tip": "Abilitare l'accesso alla fotocamera o alla biblioteca per utilizzare questa funzione.",
    "linkedin_error": "Errore di sincronizzazione da LinkedIn",
    "linkedin_no_picture": "LinkedIn non è riuscito a trovare un'immagine del profilo"
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.front_royal_form = window.locales.zh.front_royal_form || {};window.locales.zh.front_royal_form.inputs = window.locales.zh.front_royal_form.inputs || {};window.locales.zh.front_royal_form.inputs.upload_avatar = window.locales.zh.front_royal_form.inputs.upload_avatar || {};window.locales.zh.front_royal_form.inputs.upload_avatar = {...window.locales.zh.front_royal_form.inputs.upload_avatar, ...{
    "saved_automatically": "照片上传后自动保存",           
    "upload": "上传",           
    "x_mb_limit": "（{{size}}最大尺寸）",           
    "sync_from_linked_in": "从LinkedIn同步",           
    "camera_source_camera": "拍照",           
    "camera_source_photos": "选择照片",           
    "picture_access_tip": "请启用相机或图书馆访问使用此功能。",           
    "linkedin_error": "从LinkedIn同步错误",           
    "linkedin_no_picture": "LinkedIn无法找到个人资料图片"           
}
};