window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.front_royal_api_error_handler = window.locales.am.front_royal_api_error_handler || {};window.locales.am.front_royal_api_error_handler.internal_server_error = window.locales.am.front_royal_api_error_handler.internal_server_error || {};window.locales.am.front_royal_api_error_handler.internal_server_error = {...window.locales.am.front_royal_api_error_handler.internal_server_error, ...{
    "continue": "ቀጥል",           
    "unexpected_error_x": "ከሰርቨር ያልተጠበቀ ስህተት፡ {{message}}። እባክዎ እንደገና ይሞክሩ።"           
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.front_royal_api_error_handler = window.locales.ar.front_royal_api_error_handler || {};window.locales.ar.front_royal_api_error_handler.internal_server_error = window.locales.ar.front_royal_api_error_handler.internal_server_error || {};window.locales.ar.front_royal_api_error_handler.internal_server_error = {...window.locales.ar.front_royal_api_error_handler.internal_server_error, ...{
    "continue": "استمر",           
    "unexpected_error_x": "خطأ غير متوقع من الخادم: {{message}}. حاوِل مرةً أخرى."           
}
};window.locales.en = window.locales.en || {};window.locales.en.front_royal_api_error_handler = window.locales.en.front_royal_api_error_handler || {};window.locales.en.front_royal_api_error_handler.internal_server_error = window.locales.en.front_royal_api_error_handler.internal_server_error || {};window.locales.en.front_royal_api_error_handler.internal_server_error = {...window.locales.en.front_royal_api_error_handler.internal_server_error, ...{
    "continue": "Continue",
    "unexpected_error_x": "Unexpected error from server: {{message}}. Please try again."
}
};window.locales.es = window.locales.es || {};window.locales.es.front_royal_api_error_handler = window.locales.es.front_royal_api_error_handler || {};window.locales.es.front_royal_api_error_handler.internal_server_error = window.locales.es.front_royal_api_error_handler.internal_server_error || {};window.locales.es.front_royal_api_error_handler.internal_server_error = {...window.locales.es.front_royal_api_error_handler.internal_server_error, ...{
    "continue": "Continuar",           
    "unexpected_error_x": "Error inesperado del servidor: {{message}}. Por favor, vuelve a intentarlo."           
}
};window.locales.it = window.locales.it || {};window.locales.it.front_royal_api_error_handler = window.locales.it.front_royal_api_error_handler || {};window.locales.it.front_royal_api_error_handler.internal_server_error = window.locales.it.front_royal_api_error_handler.internal_server_error || {};window.locales.it.front_royal_api_error_handler.internal_server_error = {...window.locales.it.front_royal_api_error_handler.internal_server_error, ...{
    "continue": "Continua",           
    "unexpected_error_x": "Errore imprevisto dal server: {{message}}. Per favore, riprova."           
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.front_royal_api_error_handler = window.locales.zh.front_royal_api_error_handler || {};window.locales.zh.front_royal_api_error_handler.internal_server_error = window.locales.zh.front_royal_api_error_handler.internal_server_error || {};window.locales.zh.front_royal_api_error_handler.internal_server_error = {...window.locales.zh.front_royal_api_error_handler.internal_server_error, ...{
    "continue": "继续",           
    "unexpected_error_x": "服务器意外错误：{{message}}。请再试一次。"           
}
};