window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.front_royal_api_error_handler = window.locales.am.front_royal_api_error_handler || {};window.locales.am.front_royal_api_error_handler.fatal = window.locales.am.front_royal_api_error_handler.fatal || {};window.locales.am.front_royal_api_error_handler.fatal = {...window.locales.am.front_royal_api_error_handler.fatal, ...{
    "continue": "ቀጥል"           
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.front_royal_api_error_handler = window.locales.ar.front_royal_api_error_handler || {};window.locales.ar.front_royal_api_error_handler.fatal = window.locales.ar.front_royal_api_error_handler.fatal || {};window.locales.ar.front_royal_api_error_handler.fatal = {...window.locales.ar.front_royal_api_error_handler.fatal, ...{
    "continue": "استمر"           
}
};window.locales.en = window.locales.en || {};window.locales.en.front_royal_api_error_handler = window.locales.en.front_royal_api_error_handler || {};window.locales.en.front_royal_api_error_handler.fatal = window.locales.en.front_royal_api_error_handler.fatal || {};window.locales.en.front_royal_api_error_handler.fatal = {...window.locales.en.front_royal_api_error_handler.fatal, ...{
    "continue": "Continue"
}
};window.locales.es = window.locales.es || {};window.locales.es.front_royal_api_error_handler = window.locales.es.front_royal_api_error_handler || {};window.locales.es.front_royal_api_error_handler.fatal = window.locales.es.front_royal_api_error_handler.fatal || {};window.locales.es.front_royal_api_error_handler.fatal = {...window.locales.es.front_royal_api_error_handler.fatal, ...{
    "continue": "Continuar"           
}
};window.locales.it = window.locales.it || {};window.locales.it.front_royal_api_error_handler = window.locales.it.front_royal_api_error_handler || {};window.locales.it.front_royal_api_error_handler.fatal = window.locales.it.front_royal_api_error_handler.fatal || {};window.locales.it.front_royal_api_error_handler.fatal = {...window.locales.it.front_royal_api_error_handler.fatal, ...{
    "continue": "Continua"           
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.front_royal_api_error_handler = window.locales.zh.front_royal_api_error_handler || {};window.locales.zh.front_royal_api_error_handler.fatal = window.locales.zh.front_royal_api_error_handler.fatal || {};window.locales.zh.front_royal_api_error_handler.fatal = {...window.locales.zh.front_royal_api_error_handler.fatal, ...{
    "continue": "继续"           
}
};