window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.feedback = window.locales.am.feedback || {};window.locales.am.feedback.feedback_form = window.locales.am.feedback.feedback_form || {};window.locales.am.feedback.feedback_form = {...window.locales.am.feedback.feedback_form, ...{
    "contact_us_link": "እንዲሁም በቀጥታ እኛን ማግኘት ይችላሉ <a href=\"mailto:{{emailAddress}}\">{{emailAddress}}</a> ወይም የእኛን <a ng-click=\"openFAQ()\">በተደጋጋሚ የተጠየቀ ጥያቄ</a> ይመልከቱ።",           
    "just_wanted_to_say": "ሰላም፣ ለማለት ፈልጌ ነበር...",           
    "message_received": "መልዕክት ደርሷል። አመሰግናለሁ!",           
    "submit": "ያስገቡ",           
    "submit_feedback_failure": "ይቅርታ፣ የእርስዎን ግብረ መልስ ማስገባት ላይ ችግር ነበር!"           
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.feedback = window.locales.ar.feedback || {};window.locales.ar.feedback.feedback_form = window.locales.ar.feedback.feedback_form || {};window.locales.ar.feedback.feedback_form = {...window.locales.ar.feedback.feedback_form, ...{
    "contact_us_link": "كما يمكنك الاتصال بنا مباشرةً على <a href=\"mailto:{{emailAddress}}\">{{emailAddress}}</a> أو اطلع على <a ng-click=\"openFAQ()\">أسئلتنا الشائعة</a>.",           
    "just_wanted_to_say": "مرحباً، إنني أريد فقط أن أقول ...",           
    "message_received": "تم استلام الرسالة. شكراً لك!",           
    "submit": "تقديم",           
    "submit_feedback_failure": "عذراً، لقد حدثت مشكلة أثناء إرسال آرائك واقتراحاتك!"           
}
};window.locales.en = window.locales.en || {};window.locales.en.feedback = window.locales.en.feedback || {};window.locales.en.feedback.feedback_form = window.locales.en.feedback.feedback_form || {};window.locales.en.feedback.feedback_form = {...window.locales.en.feedback.feedback_form, ...{
    "contact_us_link": "You can also contact us directly at <a href=\"mailto:{{emailAddress}}\">{{emailAddress}}</a> or check out our <a ng-click=\"openFAQ()\">FAQ</a>.",
    "just_wanted_to_say": "Hi, I just wanted to say...",
    "message_received": "Message received. Thanks!",
    "submit": "Submit",
    "submit_feedback_failure": "Sorry, there was a problem submitting your feedback!"
}
};window.locales.es = window.locales.es || {};window.locales.es.feedback = window.locales.es.feedback || {};window.locales.es.feedback.feedback_form = window.locales.es.feedback.feedback_form || {};window.locales.es.feedback.feedback_form = {...window.locales.es.feedback.feedback_form, ...{
    "contact_us_link": "También puedes ponerte en contacto con nosotros en <a href=\"mailto:{{emailAddress}}\">{{emailAddress}}</a> o echar un vistazo a nuestras <a ng-click=\"openFAQ()\">FAQ</a>.",           
    "just_wanted_to_say": "Hola, sólo quería decir...",           
    "message_received": "Mensaje recibido. ¡Gracias!",           
    "submit": "Enviar",           
    "submit_feedback_failure": "¡Lo sentimos, ha habido un problema al enviar tu respuesta!"           
}
};window.locales.it = window.locales.it || {};window.locales.it.feedback = window.locales.it.feedback || {};window.locales.it.feedback.feedback_form = window.locales.it.feedback.feedback_form || {};window.locales.it.feedback.feedback_form = {...window.locales.it.feedback.feedback_form, ...{
    "contact_us_link": "Puoi anche contattarci direttamente all'indirizzo <a href=\"mailto:{{emailAddress}}\">{{emailAddress}}</a> o dare un'occhiata alla nostra <a ng-click=\"openFAQ()\">FAQ</a>.",           
    "just_wanted_to_say": "Ciao, volevo solo…",           
    "message_received": "Messaggio ricevuto. Grazie!",           
    "submit": "Inoltra",           
    "submit_feedback_failure": "Siamo spiacenti, c'è stato un problema con l'inoltro del tuo feedback!"           
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.feedback = window.locales.zh.feedback || {};window.locales.zh.feedback.feedback_form = window.locales.zh.feedback.feedback_form || {};window.locales.zh.feedback.feedback_form = {...window.locales.zh.feedback.feedback_form, ...{
    "contact_us_link": "您还可以直接通过 <a href=\"mailto:{{emailAddress}}\">{{emailAddress}}</a> 联系我们，或者查看我们的 <a ng-click=\"openFAQ()\">FAQ</a>。",           
    "just_wanted_to_say": "Hi，我只想说...",           
    "message_received": "已收到消息。谢谢！",           
    "submit": "提交",           
    "submit_feedback_failure": "抱歉，提交您的反馈时出现问题！"           
}
};