window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.front_royal_form = window.locales.am.front_royal_form || {};window.locales.am.front_royal_form.inputs = window.locales.am.front_royal_form.inputs || {};window.locales.am.front_royal_form.inputs.location_autocomplete = window.locales.am.front_royal_form.inputs.location_autocomplete || {};window.locales.am.front_royal_form.inputs.location_autocomplete = {...window.locales.am.front_royal_form.inputs.location_autocomplete, ...{
    "search_for_city": "ከተማ ፈልግ…",           
    "address": "አድራሻ",           
    "unable_to_load": "ማስጠንቀቂያ፡ ጉግል አገልግሎቶችን መጫን አልተቻለም። Quanticን ከአካባቢዎ ለመጠቀም VPN ያስፈልግዎ ይሆናል።",           
    "choose_a_city": "እባክዎ ከተማ ይምረጡ።"
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.front_royal_form = window.locales.ar.front_royal_form || {};window.locales.ar.front_royal_form.inputs = window.locales.ar.front_royal_form.inputs || {};window.locales.ar.front_royal_form.inputs.location_autocomplete = window.locales.ar.front_royal_form.inputs.location_autocomplete || {};window.locales.ar.front_royal_form.inputs.location_autocomplete = {...window.locales.ar.front_royal_form.inputs.location_autocomplete, ...{
    "address": "عنوان",
    "search_for_city": "البحث عن المدينة ...",
    "unable_to_load": "تحذير: غير قادر على تحميل خدمات Google. قد تحتاج إلى VPN من أجل استخدام Quantic من موقعك.",
    "choose_a_city": "لطفا یک شهر را انتخاب کنید."
}
};window.locales.en = window.locales.en || {};window.locales.en.front_royal_form = window.locales.en.front_royal_form || {};window.locales.en.front_royal_form.inputs = window.locales.en.front_royal_form.inputs || {};window.locales.en.front_royal_form.inputs.location_autocomplete = window.locales.en.front_royal_form.inputs.location_autocomplete || {};window.locales.en.front_royal_form.inputs.location_autocomplete = {...window.locales.en.front_royal_form.inputs.location_autocomplete, ...{
    "search_for_city": "Search for City ...",
    "address": "Address",
    "unable_to_load": "Warning: unable to load Google Services. You may need a VPN in order to use Quantic from your location.",
    "choose_a_city": "Please choose a city."
}
};window.locales.es = window.locales.es || {};window.locales.es.front_royal_form = window.locales.es.front_royal_form || {};window.locales.es.front_royal_form.inputs = window.locales.es.front_royal_form.inputs || {};window.locales.es.front_royal_form.inputs.location_autocomplete = window.locales.es.front_royal_form.inputs.location_autocomplete || {};window.locales.es.front_royal_form.inputs.location_autocomplete = {...window.locales.es.front_royal_form.inputs.location_autocomplete, ...{
    "address": "Dirección",
    "search_for_city": "Buscar Ciudad ...",
    "unable_to_load": "Advertencia: no se pueden cargar los servicios de Google. Es posible que necesite una VPN para utilizar Quantic desde su ubicación.",
    "choose_a_city": "Por favor elige una ciudad."
}
};window.locales.it = window.locales.it || {};window.locales.it.front_royal_form = window.locales.it.front_royal_form || {};window.locales.it.front_royal_form.inputs = window.locales.it.front_royal_form.inputs || {};window.locales.it.front_royal_form.inputs.location_autocomplete = window.locales.it.front_royal_form.inputs.location_autocomplete || {};window.locales.it.front_royal_form.inputs.location_autocomplete = {...window.locales.it.front_royal_form.inputs.location_autocomplete, ...{
    "address": "Indirizzo",           
    "search_for_city": "Cerca Città ...",
    "unable_to_load": "Attenzione: impossibile caricare i servizi Google. Potresti aver bisogno di una VPN per utilizzare Quantic dalla tua posizione.",
    "choose_a_city": "Si prega di scegliere una città."
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.front_royal_form = window.locales.zh.front_royal_form || {};window.locales.zh.front_royal_form.inputs = window.locales.zh.front_royal_form.inputs || {};window.locales.zh.front_royal_form.inputs.location_autocomplete = window.locales.zh.front_royal_form.inputs.location_autocomplete || {};window.locales.zh.front_royal_form.inputs.location_autocomplete = {...window.locales.zh.front_royal_form.inputs.location_autocomplete, ...{
    "address": "地址",           
    "search_for_city": "搜索城市...",           
    "unable_to_load": "提示：无法加载谷歌服务你可能需要安装VPN才能在你所在的位置使用Quantic。",           
    "choose_a_city": "请选择城市。"           
}
};