import { useState, useEffect } from 'react';
import { angularInjectorProvider } from 'Injector';
import delay from 'delay';
import { type Config, type ConfigFactory as ConfigFactoryType } from './FrontRoyalConfig.types';

// Use `useConfig` when it is possible the config has not yet been loaded.
// When suspense is true, it will suspend until the config is loaded.
// When suspense is false, it will return null until the config is loaded.
export function useConfig({ suspense }: { suspense: true }): Config;
export function useConfig({ suspense }: { suspense?: boolean }): Config | null;
export function useConfig(): Config | null;
export function useConfig({ suspense }: { suspense?: boolean } = { suspense: false }): Config | null {
    const $injector = angularInjectorProvider.injector;
    const ConfigFactory = $injector?.get<ConfigFactoryType>('ConfigFactory');
    const [config, setConfig] = useState<Config | null>(() => ConfigFactory?.getSync(true) || null);

    if (suspense && !$injector) throw delay(100);
    if (suspense && !config) throw ConfigFactory?.getConfig();

    useEffect(() => {
        ConfigFactory?.getConfig().then(setConfig);
    }, [$injector, ConfigFactory]);

    return config;
}

// use `useSyncConfig` when you know the config is already available. It will
// throw if the config is not available
export function useSyncConfig(): Config {
    const $injector = angularInjectorProvider.injector;
    if (!$injector) throw new Error('Angular injector not available');
    const ConfigFactory = $injector.get<ConfigFactoryType>('ConfigFactory');
    return ConfigFactory.getSync(true)!;
}
