window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.front_royal_api_error_handler = window.locales.am.front_royal_api_error_handler || {};window.locales.am.front_royal_api_error_handler.disconnected = window.locales.am.front_royal_api_error_handler.disconnected || {};window.locales.am.front_royal_api_error_handler.disconnected = {...window.locales.am.front_royal_api_error_handler.disconnected, ...{
    "reconnect": "እንደገና አገናኝ",           
    "we_will_try_again_when_you_are_online": "ኦንላይን ሲመለሱ እንደገና እንሞክራለን።",           
    "a_request_failed": "መልዕክት ወደ ሰርቨር መድረስ አልቻለም።",           
    "request_has_been_sent": "መልዕክቱን በድጋሚ እየላክን ነው።",           
    "reconnecting": "እንደገና በማገናኘት ላይ...",           
    "retry_now": "አሁን እንደገና ይሞክሩ",           
    "cancel": "ሰርዝ",           
    "will_retry_at": "በሰከንዶች {{seconds}} ውስጥ እንደገና እንሞክራለን።",           
    "will_retry_at_react": "በሰከንዶች {seconds} ውስጥ እንደገና እንሞክራለን።"
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.front_royal_api_error_handler = window.locales.ar.front_royal_api_error_handler || {};window.locales.ar.front_royal_api_error_handler.disconnected = window.locales.ar.front_royal_api_error_handler.disconnected || {};window.locales.ar.front_royal_api_error_handler.disconnected = {...window.locales.ar.front_royal_api_error_handler.disconnected, ...{
    "reconnect": "إعادة الاتصال",           
    "we_will_try_again_when_you_are_online": "سوف نحاول مرةً أخرى بمجرد أن تعاود الاتصال بالإنترنت.",           
    "a_request_failed": "لقد فشلت الرسالة في الوصول إلى الخادم.",           
    "request_has_been_sent": "إننا نقوم بإعادة إرسال الرسالة مرةً أخرى.",           
    "reconnecting": "جاري إعادة الاتصال...",           
    "retry_now": "إعادة المحاولة الآن",           
    "cancel": "إلغاء",           
    "will_retry_at": "سوف نعيد المحاولة في غضون {{seconds}} ثوانٍ.",           
    "will_retry_at_react": "سوف نعيد المحاولة في غضون {seconds} ثوانٍ."
}
};window.locales.en = window.locales.en || {};window.locales.en.front_royal_api_error_handler = window.locales.en.front_royal_api_error_handler || {};window.locales.en.front_royal_api_error_handler.disconnected = window.locales.en.front_royal_api_error_handler.disconnected || {};window.locales.en.front_royal_api_error_handler.disconnected = {...window.locales.en.front_royal_api_error_handler.disconnected, ...{
    "reconnect": "Reconnect",
    "we_will_try_again_when_you_are_online": "We will retry once you are back online.",
    "a_request_failed": "A message failed to reach the server.",
    "request_has_been_sent": "We are sending the message again.",
    "reconnecting": "Reconnecting...",
    "retry_now": "Retry now",
    "cancel": "Cancel",
    "will_retry_at": "We will retry in {{seconds}} seconds.",
    "will_retry_at_react": "We will retry in {seconds} seconds."
}
};window.locales.es = window.locales.es || {};window.locales.es.front_royal_api_error_handler = window.locales.es.front_royal_api_error_handler || {};window.locales.es.front_royal_api_error_handler.disconnected = window.locales.es.front_royal_api_error_handler.disconnected || {};window.locales.es.front_royal_api_error_handler.disconnected = {...window.locales.es.front_royal_api_error_handler.disconnected, ...{
    "reconnect": "Volver a conectar",           
    "reconnecting": "Reconectando...",           
    "we_will_try_again_when_you_are_online": "Volveremos a intentarlo una vez que estés de nuevo en línea.",           
    "a_request_failed": "Un mensaje no pudo alcanzar el servidor.",           
    "request_has_been_sent": "Estamos enviando el mensaje de nuevo.",           
    "retry_now": "Vuelva a intentarlo",           
    "cancel": "Cancelar",           
    "will_retry_at": "Lo intentaremos en {{seconds}} segundos.",           
    "will_retry_at_react": "Lo intentaremos en {seconds} segundos."
}
};window.locales.it = window.locales.it || {};window.locales.it.front_royal_api_error_handler = window.locales.it.front_royal_api_error_handler || {};window.locales.it.front_royal_api_error_handler.disconnected = window.locales.it.front_royal_api_error_handler.disconnected || {};window.locales.it.front_royal_api_error_handler.disconnected = {...window.locales.it.front_royal_api_error_handler.disconnected, ...{
    "reconnect": "Riconnettiti",           
    "we_will_try_again_when_you_are_online": "Riproveremo appena sarai di nuovo online.",           
    "a_request_failed": "Un messaggio non è riuscito a raggiungere il server.",           
    "request_has_been_sent": "Stiamo inviando nuovamente il messaggio.",           
    "reconnecting": "Riconnessione…",           
    "retry_now": "Riprova adesso",           
    "cancel": "Annulla",           
    "will_retry_at": "Riproveremo fra {{seconds}} secondi.",           
    "will_retry_at_react": "Riproveremo fra {seconds} secondi."
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.front_royal_api_error_handler = window.locales.zh.front_royal_api_error_handler || {};window.locales.zh.front_royal_api_error_handler.disconnected = window.locales.zh.front_royal_api_error_handler.disconnected || {};window.locales.zh.front_royal_api_error_handler.disconnected = {...window.locales.zh.front_royal_api_error_handler.disconnected, ...{
    "reconnect": "重新连接",           
    "we_will_try_again_when_you_are_online": "您重新上线后，我们将重试。",           
    "a_request_failed": "一条消息未能到达服务器。",           
    "request_has_been_sent": "我们将重新发送该消息。",           
    "reconnecting": "正在重新连接...",           
    "retry_now": "立即重试",           
    "cancel": "取消",           
    "will_retry_at": "我们将在 {{seconds}} 秒后重试。",           
    "will_retry_at_react": "我们将在 {seconds} 秒后重试。"
}
};