window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.front_royal_form = window.locales.am.front_royal_form || {};window.locales.am.front_royal_form.inputs = window.locales.am.front_royal_form.inputs || {};window.locales.am.front_royal_form.inputs.choose_an_item = window.locales.am.front_royal_form.inputs.choose_an_item || {};window.locales.am.front_royal_form.inputs.choose_an_item = {...window.locales.am.front_royal_form.inputs.choose_an_item, ...{
    "industry": "ኢንዱስትሪ",           
    "student_network_looking_for": "እየፈለኩ ያለሁት",           
    "student_network_interest": "ፍላጎቶች…",           
    "class": "ፕሮግራሞች..."
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.front_royal_form = window.locales.ar.front_royal_form || {};window.locales.ar.front_royal_form.inputs = window.locales.ar.front_royal_form.inputs || {};window.locales.ar.front_royal_form.inputs.choose_an_item = window.locales.ar.front_royal_form.inputs.choose_an_item || {};window.locales.ar.front_royal_form.inputs.choose_an_item = {...window.locales.ar.front_royal_form.inputs.choose_an_item, ...{
    "industry": "صناعة",
    "student_network_looking_for": "البحث عن",
    "student_network_interest": "الإهتمامات…",
    "class": "البرامج..."
}
};window.locales.en = window.locales.en || {};window.locales.en.front_royal_form = window.locales.en.front_royal_form || {};window.locales.en.front_royal_form.inputs = window.locales.en.front_royal_form.inputs || {};window.locales.en.front_royal_form.inputs.choose_an_item = window.locales.en.front_royal_form.inputs.choose_an_item || {};window.locales.en.front_royal_form.inputs.choose_an_item = {...window.locales.en.front_royal_form.inputs.choose_an_item, ...{
    "industry": "Industry",
    "student_network_looking_for": "Looking For",
    "student_network_interest": "Interests…",
    "class": "Programs..."
}
};window.locales.es = window.locales.es || {};window.locales.es.front_royal_form = window.locales.es.front_royal_form || {};window.locales.es.front_royal_form.inputs = window.locales.es.front_royal_form.inputs || {};window.locales.es.front_royal_form.inputs.choose_an_item = window.locales.es.front_royal_form.inputs.choose_an_item || {};window.locales.es.front_royal_form.inputs.choose_an_item = {...window.locales.es.front_royal_form.inputs.choose_an_item, ...{
    "industry": "Industria",
    "student_network_looking_for": "Buscando",
    "student_network_interest": "Intereses…",
    "class": "Programas..."
}
};window.locales.it = window.locales.it || {};window.locales.it.front_royal_form = window.locales.it.front_royal_form || {};window.locales.it.front_royal_form.inputs = window.locales.it.front_royal_form.inputs || {};window.locales.it.front_royal_form.inputs.choose_an_item = window.locales.it.front_royal_form.inputs.choose_an_item || {};window.locales.it.front_royal_form.inputs.choose_an_item = {...window.locales.it.front_royal_form.inputs.choose_an_item, ...{
    "industry": "Industria",
    "student_network_looking_for": "Cercando",
    "student_network_interest": "Interessi…",
    "class": "Programmi..."
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.front_royal_form = window.locales.zh.front_royal_form || {};window.locales.zh.front_royal_form.inputs = window.locales.zh.front_royal_form.inputs || {};window.locales.zh.front_royal_form.inputs.choose_an_item = window.locales.zh.front_royal_form.inputs.choose_an_item || {};window.locales.zh.front_royal_form.inputs.choose_an_item = {...window.locales.zh.front_royal_form.inputs.choose_an_item, ...{
    "industry": "行业",           
    "student_network_looking_for": "寻找",           
    "student_network_interest": "兴趣",           
    "class": "程式..."
}
};