window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.front_royal_form = window.locales.am.front_royal_form || {};window.locales.am.front_royal_form.inputs = window.locales.am.front_royal_form.inputs || {};window.locales.am.front_royal_form.inputs.input_const_autosuggest = window.locales.am.front_royal_form.inputs.input_const_autosuggest || {};window.locales.am.front_royal_form.inputs.input_const_autosuggest = {...window.locales.am.front_royal_form.inputs.input_const_autosuggest, ...{
    "chars_remaining": "ቀሪ"           
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.front_royal_form = window.locales.ar.front_royal_form || {};window.locales.ar.front_royal_form.inputs = window.locales.ar.front_royal_form.inputs || {};window.locales.ar.front_royal_form.inputs.input_const_autosuggest = window.locales.ar.front_royal_form.inputs.input_const_autosuggest || {};window.locales.ar.front_royal_form.inputs.input_const_autosuggest = {...window.locales.ar.front_royal_form.inputs.input_const_autosuggest, ...{
    "chars_remaining": "متبق"
}
};window.locales.en = window.locales.en || {};window.locales.en.front_royal_form = window.locales.en.front_royal_form || {};window.locales.en.front_royal_form.inputs = window.locales.en.front_royal_form.inputs || {};window.locales.en.front_royal_form.inputs.input_const_autosuggest = window.locales.en.front_royal_form.inputs.input_const_autosuggest || {};window.locales.en.front_royal_form.inputs.input_const_autosuggest = {...window.locales.en.front_royal_form.inputs.input_const_autosuggest, ...{
    "chars_remaining": "remaining"
}
};window.locales.es = window.locales.es || {};window.locales.es.front_royal_form = window.locales.es.front_royal_form || {};window.locales.es.front_royal_form.inputs = window.locales.es.front_royal_form.inputs || {};window.locales.es.front_royal_form.inputs.input_const_autosuggest = window.locales.es.front_royal_form.inputs.input_const_autosuggest || {};window.locales.es.front_royal_form.inputs.input_const_autosuggest = {...window.locales.es.front_royal_form.inputs.input_const_autosuggest, ...{
    "chars_remaining": "restante"
}
};window.locales.it = window.locales.it || {};window.locales.it.front_royal_form = window.locales.it.front_royal_form || {};window.locales.it.front_royal_form.inputs = window.locales.it.front_royal_form.inputs || {};window.locales.it.front_royal_form.inputs.input_const_autosuggest = window.locales.it.front_royal_form.inputs.input_const_autosuggest || {};window.locales.it.front_royal_form.inputs.input_const_autosuggest = {...window.locales.it.front_royal_form.inputs.input_const_autosuggest, ...{
    "chars_remaining": "residuo"
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.front_royal_form = window.locales.zh.front_royal_form || {};window.locales.zh.front_royal_form.inputs = window.locales.zh.front_royal_form.inputs || {};window.locales.zh.front_royal_form.inputs.input_const_autosuggest = window.locales.zh.front_royal_form.inputs.input_const_autosuggest || {};window.locales.zh.front_royal_form.inputs.input_const_autosuggest = {...window.locales.zh.front_royal_form.inputs.input_const_autosuggest, ...{
    "chars_remaining": "剩余"           
}
};