window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.front_royal_form = window.locales.am.front_royal_form || {};window.locales.am.front_royal_form.front_royal_form = window.locales.am.front_royal_form.front_royal_form || {};window.locales.am.front_royal_form.front_royal_form = {...window.locales.am.front_royal_form.front_royal_form, ...{
    "choose_your_top_n": "የእርስዎን ከፍተኛውን {{n}} ይምረጡ",           
    "choose_up_to_n": "እስከ {{n}} ድረስ ይምረጡ"           
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.front_royal_form = window.locales.ar.front_royal_form || {};window.locales.ar.front_royal_form.front_royal_form = window.locales.ar.front_royal_form.front_royal_form || {};window.locales.ar.front_royal_form.front_royal_form = {...window.locales.ar.front_royal_form.front_royal_form, ...{
    "choose_your_top_n": "اختر أهم {{n}} خاصة بك",           
    "choose_up_to_n": "اختيار ما يصل إلى {{n}}"
}
};window.locales.en = window.locales.en || {};window.locales.en.front_royal_form = window.locales.en.front_royal_form || {};window.locales.en.front_royal_form.front_royal_form = window.locales.en.front_royal_form.front_royal_form || {};window.locales.en.front_royal_form.front_royal_form = {...window.locales.en.front_royal_form.front_royal_form, ...{
    "choose_your_top_n": "choose your top {{n}}",
    "choose_up_to_n": "choose up to {{n}}"
}
};window.locales.es = window.locales.es || {};window.locales.es.front_royal_form = window.locales.es.front_royal_form || {};window.locales.es.front_royal_form.front_royal_form = window.locales.es.front_royal_form.front_royal_form || {};window.locales.es.front_royal_form.front_royal_form = {...window.locales.es.front_royal_form.front_royal_form, ...{
    "choose_your_top_n": "elija sus {{n}} principales",           
    "choose_up_to_n": "elegir un máximo de {{n}}"
}
};window.locales.it = window.locales.it || {};window.locales.it.front_royal_form = window.locales.it.front_royal_form || {};window.locales.it.front_royal_form.front_royal_form = window.locales.it.front_royal_form.front_royal_form || {};window.locales.it.front_royal_form.front_royal_form = {...window.locales.it.front_royal_form.front_royal_form, ...{
    "choose_your_top_n": "scegli i tuoi primi {{n}}",           
    "choose_up_to_n": "scegli fino a {{n}}"           
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.front_royal_form = window.locales.zh.front_royal_form || {};window.locales.zh.front_royal_form.front_royal_form = window.locales.zh.front_royal_form.front_royal_form || {};window.locales.zh.front_royal_form.front_royal_form = {...window.locales.zh.front_royal_form.front_royal_form, ...{
    "choose_your_top_n": "选择您的前{{n}}名",           
    "choose_up_to_n": "至多选择{{n}}"           
}
};