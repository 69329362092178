window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.front_royal_form = window.locales.am.front_royal_form || {};window.locales.am.front_royal_form.inputs = window.locales.am.front_royal_form.inputs || {};window.locales.am.front_royal_form.inputs.validate_number = window.locales.am.front_royal_form.inputs.validate_number || {};window.locales.am.front_royal_form.inputs.validate_number = {...window.locales.am.front_royal_form.inputs.validate_number, ...{
    "enter_a_number": "ከ {{min}} እስከ {{max}} ቁጥር ያስገቡ"           
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.front_royal_form = window.locales.ar.front_royal_form || {};window.locales.ar.front_royal_form.inputs = window.locales.ar.front_royal_form.inputs || {};window.locales.ar.front_royal_form.inputs.validate_number = window.locales.ar.front_royal_form.inputs.validate_number || {};window.locales.ar.front_royal_form.inputs.validate_number = {...window.locales.ar.front_royal_form.inputs.validate_number, ...{
    "enter_a_number": "أدخل رقمًا من {{min}} إلى {{max}}"
}
};window.locales.en = window.locales.en || {};window.locales.en.front_royal_form = window.locales.en.front_royal_form || {};window.locales.en.front_royal_form.inputs = window.locales.en.front_royal_form.inputs || {};window.locales.en.front_royal_form.inputs.validate_number = window.locales.en.front_royal_form.inputs.validate_number || {};window.locales.en.front_royal_form.inputs.validate_number = {...window.locales.en.front_royal_form.inputs.validate_number, ...{
    "enter_a_number": "Enter a number from {{min}} to {{max}}"
}
};window.locales.es = window.locales.es || {};window.locales.es.front_royal_form = window.locales.es.front_royal_form || {};window.locales.es.front_royal_form.inputs = window.locales.es.front_royal_form.inputs || {};window.locales.es.front_royal_form.inputs.validate_number = window.locales.es.front_royal_form.inputs.validate_number || {};window.locales.es.front_royal_form.inputs.validate_number = {...window.locales.es.front_royal_form.inputs.validate_number, ...{
    "enter_a_number": "Ingrese un número de {{min}} a {{max}}"
}
};window.locales.it = window.locales.it || {};window.locales.it.front_royal_form = window.locales.it.front_royal_form || {};window.locales.it.front_royal_form.inputs = window.locales.it.front_royal_form.inputs || {};window.locales.it.front_royal_form.inputs.validate_number = window.locales.it.front_royal_form.inputs.validate_number || {};window.locales.it.front_royal_form.inputs.validate_number = {...window.locales.it.front_royal_form.inputs.validate_number, ...{
    "enter_a_number": "Inserisci un numero da {{min}} a {{max}}"
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.front_royal_form = window.locales.zh.front_royal_form || {};window.locales.zh.front_royal_form.inputs = window.locales.zh.front_royal_form.inputs || {};window.locales.zh.front_royal_form.inputs.validate_number = window.locales.zh.front_royal_form.inputs.validate_number || {};window.locales.zh.front_royal_form.inputs.validate_number = {...window.locales.zh.front_royal_form.inputs.validate_number, ...{
    "enter_a_number": "输入从{{min}}到{{max}}的数字"           
}
};