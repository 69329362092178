window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.front_royal_form = window.locales.am.front_royal_form || {};window.locales.am.front_royal_form.inputs = window.locales.am.front_royal_form.inputs || {};window.locales.am.front_royal_form.inputs.organization_autocomplete = window.locales.am.front_royal_form.inputs.organization_autocomplete || {};window.locales.am.front_royal_form.inputs.organization_autocomplete = {...window.locales.am.front_royal_form.inputs.organization_autocomplete, ...{
    "start_typing": "ፍለጋ ለመጀመር ይተይቡ",           
    "add_new_entry": "አክል <strong>{{text}}</strong>"           
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.front_royal_form = window.locales.ar.front_royal_form || {};window.locales.ar.front_royal_form.inputs = window.locales.ar.front_royal_form.inputs || {};window.locales.ar.front_royal_form.inputs.organization_autocomplete = window.locales.ar.front_royal_form.inputs.organization_autocomplete || {};window.locales.ar.front_royal_form.inputs.organization_autocomplete = {...window.locales.ar.front_royal_form.inputs.organization_autocomplete, ...{
    "start_typing": "اكتب لبدء البحث",
    "add_new_entry": "إضافة <strong>{{text}}</strong>"
}
};window.locales.en = window.locales.en || {};window.locales.en.front_royal_form = window.locales.en.front_royal_form || {};window.locales.en.front_royal_form.inputs = window.locales.en.front_royal_form.inputs || {};window.locales.en.front_royal_form.inputs.organization_autocomplete = window.locales.en.front_royal_form.inputs.organization_autocomplete || {};window.locales.en.front_royal_form.inputs.organization_autocomplete = {...window.locales.en.front_royal_form.inputs.organization_autocomplete, ...{
    "start_typing": "Type to start searching",
    "add_new_entry": "Add <strong>{{text}}</strong>"
}
};window.locales.es = window.locales.es || {};window.locales.es.front_royal_form = window.locales.es.front_royal_form || {};window.locales.es.front_royal_form.inputs = window.locales.es.front_royal_form.inputs || {};window.locales.es.front_royal_form.inputs.organization_autocomplete = window.locales.es.front_royal_form.inputs.organization_autocomplete || {};window.locales.es.front_royal_form.inputs.organization_autocomplete = {...window.locales.es.front_royal_form.inputs.organization_autocomplete, ...{
    "start_typing": "Escriba para iniciar la búsqueda",
    "add_new_entry": "Añadir <strong>{{text}}</strong>"
}
};window.locales.it = window.locales.it || {};window.locales.it.front_royal_form = window.locales.it.front_royal_form || {};window.locales.it.front_royal_form.inputs = window.locales.it.front_royal_form.inputs || {};window.locales.it.front_royal_form.inputs.organization_autocomplete = window.locales.it.front_royal_form.inputs.organization_autocomplete || {};window.locales.it.front_royal_form.inputs.organization_autocomplete = {...window.locales.it.front_royal_form.inputs.organization_autocomplete, ...{
    "start_typing": "Digita per iniziare a cercare",           
    "add_new_entry": "Aggiungi <strong>{{text}}</strong>"           
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.front_royal_form = window.locales.zh.front_royal_form || {};window.locales.zh.front_royal_form.inputs = window.locales.zh.front_royal_form.inputs || {};window.locales.zh.front_royal_form.inputs.organization_autocomplete = window.locales.zh.front_royal_form.inputs.organization_autocomplete || {};window.locales.zh.front_royal_form.inputs.organization_autocomplete = {...window.locales.zh.front_royal_form.inputs.organization_autocomplete, ...{
    "start_typing": "输入开始搜索",           
    "add_new_entry": "添加<strong>{{text}}</strong>"           
}
};