window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.front_royal_form = window.locales.am.front_royal_form || {};window.locales.am.front_royal_form.share_buttons = window.locales.am.front_royal_form.share_buttons || {};window.locales.am.front_royal_form.share_buttons = {...window.locales.am.front_royal_form.share_buttons, ...{
    "share": "ያጋሩ:",           
    "facebook": "ፌስቡክ",           
    "linkedin": "ሊንከዲን",           
    "twitter": "ትዊተር",           
    "email": "ኢሜይል",           
    "referral_title": "Smartly Talent",           
    "referral_description": "ወደ Smartly Talent አመልክቻለሁ! በአውታረ መረቡ ውስጥ ከተቀጠሩ $500 ያግኙ።  #የሚመኙት ስራ #ሙያዎች",           
    "referral_email_title": "የእኔ አዲስ አውታረ መረብ",           
    "referral_email_description": "ሰላም \n\n፣ አሁን በSmartly Talent ተመዝግቤያለሁ እና እርስዎም መቀላቀል ሊፈልጉ ይችላሉ ብዬ አስቤ ነበር!\n\nበአውታረ መረቡ ውስጥ በማንኛውም ኩባንያ ሲቀጠሩ $500 የሚከፍልዎ አዲስ የስራ አገልግሎት ነው።\n\nለመጀመር እዚህ ጠቅ ያድርጉ፡- {{canonicalUrl}}\n\n{{signature}}"           
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.front_royal_form = window.locales.ar.front_royal_form || {};window.locales.ar.front_royal_form.share_buttons = window.locales.ar.front_royal_form.share_buttons || {};window.locales.ar.front_royal_form.share_buttons = {...window.locales.ar.front_royal_form.share_buttons, ...{
    "share": "شارك:",
    "facebook": "فيس بوك",
    "linkedin": "ينكدين",
    "twitter": "تغريد",
    "email": "البريد الإلكتروني",
    "referral_email_title": "شبكتي الجديدة",
    "referral_title": "موهبة بذكاء",
    "referral_description": "تقدمت بطلب إلى Smartly Talent! احصل على 500 دولار إذا تم تعيينك في الشبكة. D #DreamJob # العملاء",
    "referral_email_description": "مرحبًا ، لقد اشتركت للتو مع Smartly Talent واعتقدت أنك قد ترغب في الانضمام أيضًا! إنها خدمة مهنية جديدة تدفع لك 500 دولار عندما تحصل على توظيف من أي شركة في شبكتها. انقر هنا للبدء: {{canonicalUrl}} {{signature}}"
}
};window.locales.en = window.locales.en || {};window.locales.en.front_royal_form = window.locales.en.front_royal_form || {};window.locales.en.front_royal_form.share_buttons = window.locales.en.front_royal_form.share_buttons || {};window.locales.en.front_royal_form.share_buttons = {...window.locales.en.front_royal_form.share_buttons, ...{
    "share": "Share:",
    "facebook": "Facebook",
    "linkedin": "LinkedIn",
    "twitter": "Twitter",
    "email": "Email",
    "referral_title": "Smartly Talent",
    "referral_description": "I applied to Smartly Talent! Get $500 if you get hired in the network. 🚀 #DreamJob #Careers",
    "referral_email_title": "My new network",
    "referral_email_description": "Hi,\n\nI just signed up with Smartly Talent and thought you might want to join too!\n\nIt’s a new career service that pays you $500 when you get hired by any company in their network.\n\nClick here to get started: {{canonicalUrl}}\n\n{{signature}}"
}
};window.locales.es = window.locales.es || {};window.locales.es.front_royal_form = window.locales.es.front_royal_form || {};window.locales.es.front_royal_form.share_buttons = window.locales.es.front_royal_form.share_buttons || {};window.locales.es.front_royal_form.share_buttons = {...window.locales.es.front_royal_form.share_buttons, ...{
    "share": "Compartir:",
    "facebook": "Facebook",
    "linkedin": "LinkedIn",
    "twitter": "Gorjeo",
    "email": "Email",
    "referral_email_title": "Mi nueva red",
    "referral_title": "Talento inteligente",
    "referral_description": "¡Solicité Smartly Talent! Obtenga $ 500 si lo contratan en la red. 🚀 #DreamJob #Carreras",
    "referral_email_description": "Hola, ¡me acabo de registrar con Smartly Talent y pensé que también querrías unirte! Es un nuevo servicio de carrera que le paga $ 500 cuando es contratado por cualquier compañía en su red. Haga clic aquí para comenzar: {{canonicalUrl}} {{signature}}"
}
};window.locales.it = window.locales.it || {};window.locales.it.front_royal_form = window.locales.it.front_royal_form || {};window.locales.it.front_royal_form.share_buttons = window.locales.it.front_royal_form.share_buttons || {};window.locales.it.front_royal_form.share_buttons = {...window.locales.it.front_royal_form.share_buttons, ...{
    "share": "Condividere:",
    "facebook": "Facebook",
    "linkedin": "LinkedIn",
    "twitter": "cinguettio",
    "email": "E-mail",
    "referral_email_title": "La mia nuova rete",
    "referral_title": "Smartly Talent",
    "referral_description": "Ho applicato a Smartly Talent! Ottieni $ 500 se tieni assunto nella rete. 🚀 #DreamJob #Carriere",
    "referral_email_description": "Ciao, mi sono appena iscritto a Smartly Talent e ho pensato che volessi unirti anche tu! È un nuovo servizio di carriera che ti paga $ 500 quando sei assunto da qualsiasi azienda nella loro rete. Clicca qui per iniziare: {{canonicalUrl}} {{signature}}"
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.front_royal_form = window.locales.zh.front_royal_form || {};window.locales.zh.front_royal_form.share_buttons = window.locales.zh.front_royal_form.share_buttons || {};window.locales.zh.front_royal_form.share_buttons = {...window.locales.zh.front_royal_form.share_buttons, ...{
    "share": "分享：",           
    "facebook": "Facebook",           
    "linkedin": "领英",           
    "twitter": "推特（Twitter）",           
    "email": "邮箱",           
    "referral_email_title": "我的新网络",           
    "referral_title": "Smartly Talent",           
    "referral_description": "我申请加入Smartly Talent！如果你在网络中被雇佣，可获得500 美元。ðŸš€ #DreamJob #Careers",           
    "referral_email_description": "你好，\r\n\r\n我刚刚注册了Smartly Talent，我想你可能也想加入！\r\n\r\n这是一项新的职业服务，一旦你被网络中的任何公司聘用，就会向你支付500美元。\r\n\r\n点击此处开始：{{canonicalUrl}}\r\n\r\n{{signature}}"           
}
};