window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.feedback = window.locales.am.feedback || {};window.locales.am.feedback.feedback_sidebar = window.locales.am.feedback.feedback_sidebar || {};window.locales.am.feedback.feedback_sidebar = {...window.locales.am.feedback.feedback_sidebar, ...{
    "feedback": " ገብረመልስ"           
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.feedback = window.locales.ar.feedback || {};window.locales.ar.feedback.feedback_sidebar = window.locales.ar.feedback.feedback_sidebar || {};window.locales.ar.feedback.feedback_sidebar = {...window.locales.ar.feedback.feedback_sidebar, ...{
    "feedback": "الآراء والاقتراحات"           
}
};window.locales.en = window.locales.en || {};window.locales.en.feedback = window.locales.en.feedback || {};window.locales.en.feedback.feedback_sidebar = window.locales.en.feedback.feedback_sidebar || {};window.locales.en.feedback.feedback_sidebar = {...window.locales.en.feedback.feedback_sidebar, ...{
    "feedback": " Feedback"
}
};window.locales.es = window.locales.es || {};window.locales.es.feedback = window.locales.es.feedback || {};window.locales.es.feedback.feedback_sidebar = window.locales.es.feedback.feedback_sidebar || {};window.locales.es.feedback.feedback_sidebar = {...window.locales.es.feedback.feedback_sidebar, ...{
    "feedback": " Comentarios"           
}
};window.locales.it = window.locales.it || {};window.locales.it.feedback = window.locales.it.feedback || {};window.locales.it.feedback.feedback_sidebar = window.locales.it.feedback.feedback_sidebar || {};window.locales.it.feedback.feedback_sidebar = {...window.locales.it.feedback.feedback_sidebar, ...{
    "feedback": "Feedback"           
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.feedback = window.locales.zh.feedback || {};window.locales.zh.feedback.feedback_sidebar = window.locales.zh.feedback.feedback_sidebar || {};window.locales.zh.feedback.feedback_sidebar = {...window.locales.zh.feedback.feedback_sidebar, ...{
    "feedback": " 反馈"           
}
};