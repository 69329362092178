window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.front_royal_form = window.locales.am.front_royal_form || {};window.locales.am.front_royal_form.invalid_fields_links = window.locales.am.front_royal_form.invalid_fields_links || {};window.locales.am.front_royal_form.invalid_fields_links = {...window.locales.am.front_royal_form.invalid_fields_links, ...{
    "please_complete_fields": "እባክዎ ሁሉንም አስፈላጊ መስኮች ያጠናቅቁ *",           
    "please_complete_fields_in_english": "እባክዎ የሚከተሉትን መስኮች በእንግሊዝኛ ይመልሱ"
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.front_royal_form = window.locales.ar.front_royal_form || {};window.locales.ar.front_royal_form.invalid_fields_links = window.locales.ar.front_royal_form.invalid_fields_links || {};window.locales.ar.front_royal_form.invalid_fields_links = {...window.locales.ar.front_royal_form.invalid_fields_links, ...{
    "please_complete_fields": "يرجى تعبئة جميع الحقول المطلوبة *",
    "please_complete_fields_in_english": "الرجاء الإجابة على الحقول التالية باللغة الإنجليزية"
}
};window.locales.en = window.locales.en || {};window.locales.en.front_royal_form = window.locales.en.front_royal_form || {};window.locales.en.front_royal_form.invalid_fields_links = window.locales.en.front_royal_form.invalid_fields_links || {};window.locales.en.front_royal_form.invalid_fields_links = {...window.locales.en.front_royal_form.invalid_fields_links, ...{
    "please_complete_fields": "Please complete all required fields *",
    "please_complete_fields_in_english": "Please answer the following fields in English"
}
};window.locales.es = window.locales.es || {};window.locales.es.front_royal_form = window.locales.es.front_royal_form || {};window.locales.es.front_royal_form.invalid_fields_links = window.locales.es.front_royal_form.invalid_fields_links || {};window.locales.es.front_royal_form.invalid_fields_links = {...window.locales.es.front_royal_form.invalid_fields_links, ...{
    "please_complete_fields": "Por favor completa todos los campos requeridos *",
    "please_complete_fields_in_english": "Por favor conteste los siguientes campos en inglés"
}
};window.locales.it = window.locales.it || {};window.locales.it.front_royal_form = window.locales.it.front_royal_form || {};window.locales.it.front_royal_form.invalid_fields_links = window.locales.it.front_royal_form.invalid_fields_links || {};window.locales.it.front_royal_form.invalid_fields_links = {...window.locales.it.front_royal_form.invalid_fields_links, ...{
    "please_complete_fields": "Si prega di compilare tutti i campi obbligatori *",
    "please_complete_fields_in_english": "Si prega di rispondere ai seguenti campi in inglese"
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.front_royal_form = window.locales.zh.front_royal_form || {};window.locales.zh.front_royal_form.invalid_fields_links = window.locales.zh.front_royal_form.invalid_fields_links || {};window.locales.zh.front_royal_form.invalid_fields_links = {...window.locales.zh.front_royal_form.invalid_fields_links, ...{
    "please_complete_fields": "请填写所有必填字段*",           
    "please_complete_fields_in_english": "请以英文回答以下字段"
}
};