window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.front_royal_form = window.locales.am.front_royal_form || {};window.locales.am.front_royal_form.inputs = window.locales.am.front_royal_form.inputs || {};window.locales.am.front_royal_form.inputs.salary = window.locales.am.front_royal_form.inputs.salary || {};window.locales.am.front_royal_form.inputs.salary = {...window.locales.am.front_royal_form.inputs.salary, ...{
    "open_to_discussion": "ለውይይት ክፈት"           
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.front_royal_form = window.locales.ar.front_royal_form || {};window.locales.ar.front_royal_form.inputs = window.locales.ar.front_royal_form.inputs || {};window.locales.ar.front_royal_form.inputs.salary = window.locales.ar.front_royal_form.inputs.salary || {};window.locales.ar.front_royal_form.inputs.salary = {...window.locales.ar.front_royal_form.inputs.salary, ...{
    "open_to_discussion": "مفتوحة للمناقشة"
}
};window.locales.en = window.locales.en || {};window.locales.en.front_royal_form = window.locales.en.front_royal_form || {};window.locales.en.front_royal_form.inputs = window.locales.en.front_royal_form.inputs || {};window.locales.en.front_royal_form.inputs.salary = window.locales.en.front_royal_form.inputs.salary || {};window.locales.en.front_royal_form.inputs.salary = {...window.locales.en.front_royal_form.inputs.salary, ...{
    "open_to_discussion": "Open to Discussion"
}
};window.locales.es = window.locales.es || {};window.locales.es.front_royal_form = window.locales.es.front_royal_form || {};window.locales.es.front_royal_form.inputs = window.locales.es.front_royal_form.inputs || {};window.locales.es.front_royal_form.inputs.salary = window.locales.es.front_royal_form.inputs.salary || {};window.locales.es.front_royal_form.inputs.salary = {...window.locales.es.front_royal_form.inputs.salary, ...{
    "open_to_discussion": "Abierto a discusión"
}
};window.locales.it = window.locales.it || {};window.locales.it.front_royal_form = window.locales.it.front_royal_form || {};window.locales.it.front_royal_form.inputs = window.locales.it.front_royal_form.inputs || {};window.locales.it.front_royal_form.inputs.salary = window.locales.it.front_royal_form.inputs.salary || {};window.locales.it.front_royal_form.inputs.salary = {...window.locales.it.front_royal_form.inputs.salary, ...{
    "open_to_discussion": "Aperto alla discussione"
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.front_royal_form = window.locales.zh.front_royal_form || {};window.locales.zh.front_royal_form.inputs = window.locales.zh.front_royal_form.inputs || {};window.locales.zh.front_royal_form.inputs.salary = window.locales.zh.front_royal_form.inputs.salary || {};window.locales.zh.front_royal_form.inputs.salary = {...window.locales.zh.front_royal_form.inputs.salary, ...{
    "open_to_discussion": "接受讨论"           
}
};