window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.feedback = window.locales.am.feedback || {};window.locales.am.feedback.feedback_modal = window.locales.am.feedback.feedback_modal || {};window.locales.am.feedback.feedback_modal = {...window.locales.am.feedback.feedback_modal, ...{
    "give_feedback": "ግብረ መልስ ይስጡ"           
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.feedback = window.locales.ar.feedback || {};window.locales.ar.feedback.feedback_modal = window.locales.ar.feedback.feedback_modal || {};window.locales.ar.feedback.feedback_modal = {...window.locales.ar.feedback.feedback_modal, ...{
    "give_feedback": "امنحنا آرائك واقتراحاتك"           
}
};window.locales.en = window.locales.en || {};window.locales.en.feedback = window.locales.en.feedback || {};window.locales.en.feedback.feedback_modal = window.locales.en.feedback.feedback_modal || {};window.locales.en.feedback.feedback_modal = {...window.locales.en.feedback.feedback_modal, ...{
    "give_feedback": "Give Feedback"
}
};window.locales.es = window.locales.es || {};window.locales.es.feedback = window.locales.es.feedback || {};window.locales.es.feedback.feedback_modal = window.locales.es.feedback.feedback_modal || {};window.locales.es.feedback.feedback_modal = {...window.locales.es.feedback.feedback_modal, ...{
    "give_feedback": "Dejar comentarios"           
}
};window.locales.it = window.locales.it || {};window.locales.it.feedback = window.locales.it.feedback || {};window.locales.it.feedback.feedback_modal = window.locales.it.feedback.feedback_modal || {};window.locales.it.feedback.feedback_modal = {...window.locales.it.feedback.feedback_modal, ...{
    "give_feedback": "Invia Feedback"           
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.feedback = window.locales.zh.feedback || {};window.locales.zh.feedback.feedback_modal = window.locales.zh.feedback.feedback_modal || {};window.locales.zh.feedback.feedback_modal = {...window.locales.zh.feedback.feedback_modal, ...{
    "give_feedback": "提供反馈"           
}
};