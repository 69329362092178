window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.front_royal_form = window.locales.am.front_royal_form || {};window.locales.am.front_royal_form.inputs = window.locales.am.front_royal_form.inputs || {};window.locales.am.front_royal_form.inputs.add_an_item = window.locales.am.front_royal_form.inputs.add_an_item || {};window.locales.am.front_royal_form.inputs.add_an_item = {...window.locales.am.front_royal_form.inputs.add_an_item, ...{
    "add": "አክል",           
    "add_skill_interest": "ችሎታ / ፍላጎት አክል",           
    "done": "ተከናውኗል",           
    "tip": "<strong>ጠቃሚ ምክር:</strong> ለበለጠ ተፅዕኖ እያንዳንዱን መስመር በድርጊት ግስ ይጀምሩ።",           
    "examples": "<strong>ምሳሌዎች:</strong> መር፣ ተገንብቷል፣ አድጓል፣ ተጀምሯል፣ ተተግብሯል፣ ነቅቷል፣ የሚተዳደር፣ የተነደፈ፣ የተገመገመ፣ የተመረመረ፣ የተመከረ፣ የሚለይ፣ የተሸለመ።",           
    "exceeds_max_length": "ከፍተኛውን የቁምፊ ርዝመት በልጧል"           
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.front_royal_form = window.locales.ar.front_royal_form || {};window.locales.ar.front_royal_form.inputs = window.locales.ar.front_royal_form.inputs || {};window.locales.ar.front_royal_form.inputs.add_an_item = window.locales.ar.front_royal_form.inputs.add_an_item || {};window.locales.ar.front_royal_form.inputs.add_an_item = {...window.locales.ar.front_royal_form.inputs.add_an_item, ...{
    "add": "إضافة",           
    "add_skill_interest": "إضافة مهارة/اهتمام",           
    "done": "تم",           
    "tip": "<strong> نصيحة: </strong> ابدأ كل سطر بفعل مبني للمعلوم للتأثير بشكلٍ أكبر.",           
    "examples": "<strong> أمثلة: </strong> وُجّهت، بُنيت، نُمّيت، اُطلقت، غُرست، بُدأت، أُديرت، صُممت، قُيّمت، بُحثت، نُصح بها، حُددت، مُنحت.",           
    "exceeds_max_length": "يتجاوز الحد الأقصى لطول حرف"
}
};window.locales.en = window.locales.en || {};window.locales.en.front_royal_form = window.locales.en.front_royal_form || {};window.locales.en.front_royal_form.inputs = window.locales.en.front_royal_form.inputs || {};window.locales.en.front_royal_form.inputs.add_an_item = window.locales.en.front_royal_form.inputs.add_an_item || {};window.locales.en.front_royal_form.inputs.add_an_item = {...window.locales.en.front_royal_form.inputs.add_an_item, ...{
    "add": "Add",
    "add_skill_interest": "add a skill / interest",
    "done": "Done",
    "tip": "<strong>TIP:</strong> Begin each line with an action verb for greatest impact.",
    "examples": "<strong>EXAMPLES:</strong> Led, Built, Grew, Launched, Implemented, Initiated, Managed, Designed, Evaluated, Researched, Advised, Identified, Awarded.",
    "exceeds_max_length": "Exceeds maximum character length"
}
};window.locales.es = window.locales.es || {};window.locales.es.front_royal_form = window.locales.es.front_royal_form || {};window.locales.es.front_royal_form.inputs = window.locales.es.front_royal_form.inputs || {};window.locales.es.front_royal_form.inputs.add_an_item = window.locales.es.front_royal_form.inputs.add_an_item || {};window.locales.es.front_royal_form.inputs.add_an_item = {...window.locales.es.front_royal_form.inputs.add_an_item, ...{
    "add": "Añadir",           
    "add_skill_interest": "Añada una competencia / interés",           
    "done": "Terminado",           
    "tip": "<strong> Consejo:</strong> comience cada línea con un verbo de acción para tener el mayor impacto.",           
    "examples": "<strong> EJEMPLOS: </strong> lideré, creé, desarrollé, implementé, inicié, dirigí, diseñé, evalué, investigué, aconsejé, identifiqué, premié.",           
    "exceeds_max_length": "Excede la longitud máxima de caracteres"
}
};window.locales.it = window.locales.it || {};window.locales.it.front_royal_form = window.locales.it.front_royal_form || {};window.locales.it.front_royal_form.inputs = window.locales.it.front_royal_form.inputs || {};window.locales.it.front_royal_form.inputs.add_an_item = window.locales.it.front_royal_form.inputs.add_an_item || {};window.locales.it.front_royal_form.inputs.add_an_item = {...window.locales.it.front_royal_form.inputs.add_an_item, ...{
    "add": "Aggiungi",           
    "add_skill_interest": "aggiungi una competenza / un interesse",           
    "done": "Fine",           
    "tip": "<strong>SUGGERIMENTO:</strong> Comincia ogni frase con un verbo attivo per avere maggiore impatto.",           
    "examples": "<strong>ESEMPI:</strong> Guidato, Costruito, Accresciuto, Lanciato, Implementato, Avviato, Gestito, Progettato, Valutato, Ricercato, Consigliato, Identificato, Premiato.",           
    "exceeds_max_length": "Lunghezza massima caratteri superata"           
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.front_royal_form = window.locales.zh.front_royal_form || {};window.locales.zh.front_royal_form.inputs = window.locales.zh.front_royal_form.inputs || {};window.locales.zh.front_royal_form.inputs.add_an_item = window.locales.zh.front_royal_form.inputs.add_an_item || {};window.locales.zh.front_royal_form.inputs.add_an_item = {...window.locales.zh.front_royal_form.inputs.add_an_item, ...{
    "add": "添加",           
    "add_skill_interest": "添加技能/兴趣",           
    "done": "完成",           
    "tip": "<strong>小窍门：</strong> 在每个句子的开头使用行为动词以获得最佳效果。",           
    "examples": "<strong>例子：</strong> 领导，建造，扩展，开展，实行，开始，管理，设计，评估，研究，建议，识别，奖励。",           
    "exceeds_max_length": "超过最大字符长度"           
}
};