window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.front_royal_form = window.locales.am.front_royal_form || {};window.locales.am.front_royal_form.inputs = window.locales.am.front_royal_form.inputs || {};window.locales.am.front_royal_form.inputs.choose_a_date = window.locales.am.front_royal_form.inputs.choose_a_date || {};window.locales.am.front_royal_form.inputs.choose_a_date = {...window.locales.am.front_royal_form.inputs.choose_a_date, ...{
    "month": "ወር",           
    "year": "አመት",           
    "current": "የአሁኑ?",           
    "maxdate_error": "የማብቂያ ቀን ከመጀመሪያው ቀን በኋላ መሆን አለበት"           
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.front_royal_form = window.locales.ar.front_royal_form || {};window.locales.ar.front_royal_form.inputs = window.locales.ar.front_royal_form.inputs || {};window.locales.ar.front_royal_form.inputs.choose_a_date = window.locales.ar.front_royal_form.inputs.choose_a_date || {};window.locales.ar.front_royal_form.inputs.choose_a_date = {...window.locales.ar.front_royal_form.inputs.choose_a_date, ...{
    "month": "الشهر",           
    "year": "العام",           
    "current": "الحالي",           
    "maxdate_error": "يجب أن يكون تاريخ الانتهاء بعد تاريخ البدء"
}
};window.locales.en = window.locales.en || {};window.locales.en.front_royal_form = window.locales.en.front_royal_form || {};window.locales.en.front_royal_form.inputs = window.locales.en.front_royal_form.inputs || {};window.locales.en.front_royal_form.inputs.choose_a_date = window.locales.en.front_royal_form.inputs.choose_a_date || {};window.locales.en.front_royal_form.inputs.choose_a_date = {...window.locales.en.front_royal_form.inputs.choose_a_date, ...{
    "month": "Month",
    "year": "Year",
    "current": "Current?",
    "maxdate_error": "End date must be after start date"
}
};window.locales.es = window.locales.es || {};window.locales.es.front_royal_form = window.locales.es.front_royal_form || {};window.locales.es.front_royal_form.inputs = window.locales.es.front_royal_form.inputs || {};window.locales.es.front_royal_form.inputs.choose_a_date = window.locales.es.front_royal_form.inputs.choose_a_date || {};window.locales.es.front_royal_form.inputs.choose_a_date = {...window.locales.es.front_royal_form.inputs.choose_a_date, ...{
    "month": "Mes",           
    "year": "Año",           
    "current": "Actual?",           
    "maxdate_error": "La fecha de finalización debe ser posterior a la fecha de inicio"
}
};window.locales.it = window.locales.it || {};window.locales.it.front_royal_form = window.locales.it.front_royal_form || {};window.locales.it.front_royal_form.inputs = window.locales.it.front_royal_form.inputs || {};window.locales.it.front_royal_form.inputs.choose_a_date = window.locales.it.front_royal_form.inputs.choose_a_date || {};window.locales.it.front_royal_form.inputs.choose_a_date = {...window.locales.it.front_royal_form.inputs.choose_a_date, ...{
    "month": "Mese",           
    "year": "Anno",           
    "current": "Attualmente?",           
    "maxdate_error": "La data di fine deve essere successiva alla data di inizio"
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.front_royal_form = window.locales.zh.front_royal_form || {};window.locales.zh.front_royal_form.inputs = window.locales.zh.front_royal_form.inputs || {};window.locales.zh.front_royal_form.inputs.choose_a_date = window.locales.zh.front_royal_form.inputs.choose_a_date || {};window.locales.zh.front_royal_form.inputs.choose_a_date = {...window.locales.zh.front_royal_form.inputs.choose_a_date, ...{
    "month": "月",           
    "year": "年",           
    "current": "当前",           
    "maxdate_error": "结束日期必须晚于开始日期"           
}
};