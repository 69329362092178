window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.front_royal_api_error_handler = window.locales.am.front_royal_api_error_handler || {};window.locales.am.front_royal_api_error_handler.disconnected_mobile_init = window.locales.am.front_royal_api_error_handler.disconnected_mobile_init || {};window.locales.am.front_royal_api_error_handler.disconnected_mobile_init = {...window.locales.am.front_royal_api_error_handler.disconnected_mobile_init, ...{
    "network_offline": "አውታረ መረብዎ ከመስመር ውጪ የሆነ ይመስላል። እባክዎ ለመቀጠል የWi-Fi ወይም የተንቀሳቃሽ ስልክ ግንኙነትን ያንቁ።",           
    "connection_detected": "ግንኙነት ተገኝቷል!",           
    "loading": "በመጫን ላይ..."           
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.front_royal_api_error_handler = window.locales.ar.front_royal_api_error_handler || {};window.locales.ar.front_royal_api_error_handler.disconnected_mobile_init = window.locales.ar.front_royal_api_error_handler.disconnected_mobile_init || {};window.locales.ar.front_royal_api_error_handler.disconnected_mobile_init = {...window.locales.ar.front_royal_api_error_handler.disconnected_mobile_init, ...{
    "network_offline": "يبدو أن شبكتك غير متصلة بالإنترنت. الرجاء تمكين اتصال Wi-Fi أو شبكة خلوية للمتابعة.",
    "connection_detected": "تم اكتشاف الاتصال!",
    "loading": "جار التحميل ..."
}
};window.locales.en = window.locales.en || {};window.locales.en.front_royal_api_error_handler = window.locales.en.front_royal_api_error_handler || {};window.locales.en.front_royal_api_error_handler.disconnected_mobile_init = window.locales.en.front_royal_api_error_handler.disconnected_mobile_init || {};window.locales.en.front_royal_api_error_handler.disconnected_mobile_init = {...window.locales.en.front_royal_api_error_handler.disconnected_mobile_init, ...{
    "network_offline": "Your network appears to be offline. Please enable a Wi-Fi or cellular connection to continue.",
    "connection_detected": "Connection detected!",
    "loading": "Loading ..."
}
};window.locales.es = window.locales.es || {};window.locales.es.front_royal_api_error_handler = window.locales.es.front_royal_api_error_handler || {};window.locales.es.front_royal_api_error_handler.disconnected_mobile_init = window.locales.es.front_royal_api_error_handler.disconnected_mobile_init || {};window.locales.es.front_royal_api_error_handler.disconnected_mobile_init = {...window.locales.es.front_royal_api_error_handler.disconnected_mobile_init, ...{
    "network_offline": "Su red parece estar fuera de línea. Por favor, habilite una conexión Wi-Fi o celular para continuar.",
    "connection_detected": "Conexión detectada!",
    "loading": "Cargando ..."
}
};window.locales.it = window.locales.it || {};window.locales.it.front_royal_api_error_handler = window.locales.it.front_royal_api_error_handler || {};window.locales.it.front_royal_api_error_handler.disconnected_mobile_init = window.locales.it.front_royal_api_error_handler.disconnected_mobile_init || {};window.locales.it.front_royal_api_error_handler.disconnected_mobile_init = {...window.locales.it.front_royal_api_error_handler.disconnected_mobile_init, ...{
    "network_offline": "La tua rete sembra offline. Si prega di abilitare una connessione Wi-Fi o cellulare per continuare.",
    "connection_detected": "Connessione rilevata!",
    "loading": "Caricamento in corso ..."
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.front_royal_api_error_handler = window.locales.zh.front_royal_api_error_handler || {};window.locales.zh.front_royal_api_error_handler.disconnected_mobile_init = window.locales.zh.front_royal_api_error_handler.disconnected_mobile_init || {};window.locales.zh.front_royal_api_error_handler.disconnected_mobile_init = {...window.locales.zh.front_royal_api_error_handler.disconnected_mobile_init, ...{
    "network_offline": "你的网络似乎已离线。请开启Wi-Fi或蜂窝数据连接以继续。",           
    "connection_detected": "检测到连接！",           
    "loading": "加载中 ..."           
}
};