window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.front_royal_api_error_handler = window.locales.am.front_royal_api_error_handler || {};window.locales.am.front_royal_api_error_handler.logged_out = window.locales.am.front_royal_api_error_handler.logged_out || {};window.locales.am.front_royal_api_error_handler.logged_out = {...window.locales.am.front_royal_api_error_handler.logged_out, ...{
    "login_with_saml": "ዘግተው ወጥተዋል። እንደገና ለማስገባት <a ng-click=\"loginWithSaml()\">እዚህ ጠቅ</a> ያድርጉ"           
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.front_royal_api_error_handler = window.locales.ar.front_royal_api_error_handler || {};window.locales.ar.front_royal_api_error_handler.logged_out = window.locales.ar.front_royal_api_error_handler.logged_out || {};window.locales.ar.front_royal_api_error_handler.logged_out = {...window.locales.ar.front_royal_api_error_handler.logged_out, ...{
    "login_with_saml": "لقد تم تسجيل الخروج. <a ng-click=\"loginWithSaml()\">انقر هنا</a> لتسجيل الدخول مرةً أخرى"           
}
};window.locales.en = window.locales.en || {};window.locales.en.front_royal_api_error_handler = window.locales.en.front_royal_api_error_handler || {};window.locales.en.front_royal_api_error_handler.logged_out = window.locales.en.front_royal_api_error_handler.logged_out || {};window.locales.en.front_royal_api_error_handler.logged_out = {...window.locales.en.front_royal_api_error_handler.logged_out, ...{
    "login_with_saml": "You have been logged out. <a ng-click=\"loginWithSaml()\">Click here</a> to log in again"
}
};window.locales.es = window.locales.es || {};window.locales.es.front_royal_api_error_handler = window.locales.es.front_royal_api_error_handler || {};window.locales.es.front_royal_api_error_handler.logged_out = window.locales.es.front_royal_api_error_handler.logged_out || {};window.locales.es.front_royal_api_error_handler.logged_out = {...window.locales.es.front_royal_api_error_handler.logged_out, ...{
    "login_with_saml": "Has sido desconectado. <a ng-click=\"loginWithSaml()\">Haz clic aquí</a> para iniciar sesión de nuevo"           
}
};window.locales.it = window.locales.it || {};window.locales.it.front_royal_api_error_handler = window.locales.it.front_royal_api_error_handler || {};window.locales.it.front_royal_api_error_handler.logged_out = window.locales.it.front_royal_api_error_handler.logged_out || {};window.locales.it.front_royal_api_error_handler.logged_out = {...window.locales.it.front_royal_api_error_handler.logged_out, ...{
    "login_with_saml": "Sei stato disconnesso. <a ng-click=\"loginWithSaml()\">Fai clic qui</a> per accedere nuovamente"           
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.front_royal_api_error_handler = window.locales.zh.front_royal_api_error_handler || {};window.locales.zh.front_royal_api_error_handler.logged_out = window.locales.zh.front_royal_api_error_handler.logged_out || {};window.locales.zh.front_royal_api_error_handler.logged_out = {...window.locales.zh.front_royal_api_error_handler.logged_out, ...{
    "login_with_saml": "您已注销。<a ng-click=\"loginWithSaml()\">单击此处</a>重新登录"           
}
};