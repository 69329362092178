window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.front_royal_form = window.locales.am.front_royal_form || {};window.locales.am.front_royal_form.inputs = window.locales.am.front_royal_form.inputs || {};window.locales.am.front_royal_form.inputs.copy_text = window.locales.am.front_royal_form.inputs.copy_text || {};window.locales.am.front_royal_form.inputs.copy_text = {...window.locales.am.front_royal_form.inputs.copy_text, ...{
    "copy_link": "ማስፈንጠሪያ ቅዳ",           
    "copied": "ተገልብጧል"           
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.front_royal_form = window.locales.ar.front_royal_form || {};window.locales.ar.front_royal_form.inputs = window.locales.ar.front_royal_form.inputs || {};window.locales.ar.front_royal_form.inputs.copy_text = window.locales.ar.front_royal_form.inputs.copy_text || {};window.locales.ar.front_royal_form.inputs.copy_text = {...window.locales.ar.front_royal_form.inputs.copy_text, ...{
    "copy_link": "انسخ الرابط",
    "copied": "نسخ"
}
};window.locales.en = window.locales.en || {};window.locales.en.front_royal_form = window.locales.en.front_royal_form || {};window.locales.en.front_royal_form.inputs = window.locales.en.front_royal_form.inputs || {};window.locales.en.front_royal_form.inputs.copy_text = window.locales.en.front_royal_form.inputs.copy_text || {};window.locales.en.front_royal_form.inputs.copy_text = {...window.locales.en.front_royal_form.inputs.copy_text, ...{
    "copy_link": "Copy Link",
    "copied": "Copied"
}
};window.locales.es = window.locales.es || {};window.locales.es.front_royal_form = window.locales.es.front_royal_form || {};window.locales.es.front_royal_form.inputs = window.locales.es.front_royal_form.inputs || {};window.locales.es.front_royal_form.inputs.copy_text = window.locales.es.front_royal_form.inputs.copy_text || {};window.locales.es.front_royal_form.inputs.copy_text = {...window.locales.es.front_royal_form.inputs.copy_text, ...{
    "copy_link": "Copiar link",
    "copied": "Copiado"
}
};window.locales.it = window.locales.it || {};window.locales.it.front_royal_form = window.locales.it.front_royal_form || {};window.locales.it.front_royal_form.inputs = window.locales.it.front_royal_form.inputs || {};window.locales.it.front_royal_form.inputs.copy_text = window.locales.it.front_royal_form.inputs.copy_text || {};window.locales.it.front_royal_form.inputs.copy_text = {...window.locales.it.front_royal_form.inputs.copy_text, ...{
    "copy_link": "Copia link",
    "copied": "Copiato"
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.front_royal_form = window.locales.zh.front_royal_form || {};window.locales.zh.front_royal_form.inputs = window.locales.zh.front_royal_form.inputs || {};window.locales.zh.front_royal_form.inputs.copy_text = window.locales.zh.front_royal_form.inputs.copy_text || {};window.locales.zh.front_royal_form.inputs.copy_text = {...window.locales.zh.front_royal_form.inputs.copy_text, ...{
    "copy_link": "复制链接",           
    "copied": "已复制"           
}
};