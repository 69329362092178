window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.front_royal_form = window.locales.am.front_royal_form || {};window.locales.am.front_royal_form.selected_pills = window.locales.am.front_royal_form.selected_pills || {};window.locales.am.front_royal_form.selected_pills = {...window.locales.am.front_royal_form.selected_pills, ...{
    "desc_input": "ብጁ {{type}} ለማከል ግቤትን ይጠቀሙ።",           
    "desc": "እንደገና ለመደርደር ይጎትቱና ያውርዱ። ብጁ {{type}} ለማከል ግቤትን ይጠቀሙ።",           
    "up_to_num_input": "<b>እስከ ድረስ {{num}}</b> {{type}}ለማከል ግቤትን ይጠቀሙ።",           
    "up_to_num": "እንደገና ለመደርደር ይጎትቱና ያውርዱ። <b>እስከ ድረስ {{num}}</b> {{type}}ለማከል ግቤትን ይጠቀሙ።",           
    "simple_instructions": "እንደገና ለመደርደር ይጎትቱና ያውርዱ።"           
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.front_royal_form = window.locales.ar.front_royal_form || {};window.locales.ar.front_royal_form.selected_pills = window.locales.ar.front_royal_form.selected_pills || {};window.locales.ar.front_royal_form.selected_pills = {...window.locales.ar.front_royal_form.selected_pills, ...{
    "desc_input": "استخدم الإدخال لإضافة {{type}} مخصص.",
    "desc": "السحب والإفلات لإعادة الترتيب. استخدم الإدخال لإضافة {{type}} مخصص.",
    "up_to_num_input": "استخدم الإدخال لإضافة <b>ما يصل إلى {{num}}</b> {{type}} .",
    "up_to_num": "السحب والإفلات لإعادة الترتيب. استخدم الإدخال لإضافة <b>ما يصل إلى {{num}}</b> {{type}} .",
    "simple_instructions": "السحب والإفلات لإعادة الترتيب."
}
};window.locales.en = window.locales.en || {};window.locales.en.front_royal_form = window.locales.en.front_royal_form || {};window.locales.en.front_royal_form.selected_pills = window.locales.en.front_royal_form.selected_pills || {};window.locales.en.front_royal_form.selected_pills = {...window.locales.en.front_royal_form.selected_pills, ...{
    "desc_input": "Use input to add custom {{type}}.",
    "desc": "Drag and drop to reorder. Use input to add custom {{type}}.",
    "up_to_num_input": "Use input to add <b>up to {{num}}</b> {{type}}.",
    "up_to_num": "Drag and drop to reorder. Use input to add <b>up to {{num}}</b> {{type}}.",
    "simple_instructions": "Drag and drop to reorder."
}
};window.locales.es = window.locales.es || {};window.locales.es.front_royal_form = window.locales.es.front_royal_form || {};window.locales.es.front_royal_form.selected_pills = window.locales.es.front_royal_form.selected_pills || {};window.locales.es.front_royal_form.selected_pills = {...window.locales.es.front_royal_form.selected_pills, ...{
    "desc_input": "Use la entrada para agregar {{type}} .",
    "desc": "Arrastra y suelta para reordenar. Use la entrada para agregar {{type}} .",
    "up_to_num_input": "Use entrada para sumar <b>hasta {{num}}</b> {{type}} .",
    "up_to_num": "Arrastra y suelta para reordenar. Use entrada para sumar <b>hasta {{num}}</b> {{type}} .",
    "simple_instructions": "Arrastra y suelta para reordenar."
}
};window.locales.it = window.locales.it || {};window.locales.it.front_royal_form = window.locales.it.front_royal_form || {};window.locales.it.front_royal_form.selected_pills = window.locales.it.front_royal_form.selected_pills || {};window.locales.it.front_royal_form.selected_pills = {...window.locales.it.front_royal_form.selected_pills, ...{
    "desc_input": "Usa input per aggiungere {{type}} .",
    "desc": "Trascina e rilascia per riordinare. Usa input per aggiungere {{type}} .",
    "up_to_num_input": "Usa input per aggiungere <b>fino a {{num}}</b> {{type}} .",
    "up_to_num": "Trascina e rilascia per riordinare. Usa input per aggiungere <b>fino a {{num}}</b> {{type}} .",
    "simple_instructions": "Trascina e rilascia per riordinare."
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.front_royal_form = window.locales.zh.front_royal_form || {};window.locales.zh.front_royal_form.selected_pills = window.locales.zh.front_royal_form.selected_pills || {};window.locales.zh.front_royal_form.selected_pills = {...window.locales.zh.front_royal_form.selected_pills, ...{
    "desc_input": "使用输入添加自定义{{type}}。",           
    "desc": "拖放以重新排序。使用输入添加自定义{{type}}。",           
    "up_to_num_input": "使用输入添加<b>至多{{num}}</b>{{type}}。",           
    "up_to_num": "拖放以重新排序。使用输入添加<b>至多{{num}}</b>{{type}}。",           
    "simple_instructions": "拖放以重新排序。"           
}
};